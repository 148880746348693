<template>
  <div class="flex items-center justify-center">
    <div class="max-w-md w-full p-10 rounded-lg shadow-md bg-white">
      
      <!-- Messages area -->
      <div class="w-full mt-6">
        <span class="block px-3 font-semibold pb-6">
          <font-awesome-icon icon="check" class="text-5xl p-2 mx-auto my-6 text-green-600
            bg-white rounded-full" />
          <br>
          Ссылка отправлена!
        </span>
      </div>

    </div>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'SuccessModal',
  components: { Loading, FadeTransition },
  data: function() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
