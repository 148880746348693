<template>
  <div>
    <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

      <!-- Loading screen -->
      <div v-if="isLoading" key="animation">
        <LoadingScreen />
      </div>

      <!-- Main content -->
      <div v-if="!isLoading" class="container mx-auto" key="content">
        <VideoPlayer :id="video_id" :url="video.source_url" />

        <!-- Video description -->
        <div class="flex flex-wrap pr-2 md:pr-4 lg:pr-8">
          <p class="w-1/3 text-sm pt-3 text-left text-secondary font-semibold">
            Эксперт: {{ video.expert }}
          </p>

          <div v-if="isUserActive" class="w-2/3 pt-2 flex-wrap lg:flex-no-wrap flex justify-end"> 

            <!-- Likes -->
            <button
              @click="likeOrUnlike(video.id)"
              class="block w-1/2 text-gray-600 text-right"
            >
              <p>
                <span class="text-sm font-semibold hover:text-primary-400" :class="{'text-primary-500' : video.like}">
                  Мне нравится<font-awesome-icon icon="heart" class="text-lg inline-block mx-1"/>
                </span>
                {{video.likes}}
              </p>
            </button>

            <!-- Favourite -->
            <button
              v-if="!video.isFavourite"
              @click="addToFavourites(video.id)"
              class="block pl-6 text-gray-600 text-right text-lg font-semibold hover:text-primary-400"
            >
              <span class="text-sm">Сохранить в избранное</span><font-awesome-icon icon="star" class="ml-1"/>
            </button>
            <button
              v-if="video.isFavourite"
              @click="deleteFromFavourites(video.id)"
              class="block pl-6 text-lg text-right font-semibold text-primary-600 hover:text-primary-400"
            >
              <span class="text-sm">В избранном</span><font-awesome-icon icon="star" class="ml-1"/>
            </button>
          </div>

          <p v-if="video.specialities" class="w-full text-md pt-3 text-left font-semibold">
            Рекомендовано для специальностей: {{ video.specialities }}
          </p>
          <p class="w-full text-md my-4 text-left">
            Видео опубликовано: {{ video.createdAt }}<br>
            {{ video.description }}
          </p>

          <p v-if="user" class="w-full text-md pt-3 text-seconday text-left font-semibold">
            Поделится ссылкой на видеоролик:
          </p>
          <div v-if="user" class="relative flex flex-no-wrap my-4 w-full">
            <input
              v-model="sendLinkEmail"
              aria-label="Email"
              class="appearance-none relative block w-full md:w-1/2 px-4 py-2 border placeholder-gray-500
              text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-md
              sm:leading-5 focus:border-green-300 text-primary-500"
              placeholder="Адрес электронной почты"
            />
            <div
              @click="verifyCaptcha()"
              class="ml-3 cursor-pointer rounded-md p-2 px-4 text-gray-200 bg-primary-600 border-primary-600 
                font-semibold border-2 hover:text-white hover:bg-primary-400 hover:border-primary-400"
            >
              Отправить
            </div>

            <!-- Input field validation icon with animation -->
            <!--<slide-x-left-transition :duration="300" mode="out-in">
              <div v-if="$v.user.email.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
                <slide-x-left-transition :duration="300" mode="out-in">
                  <font-awesome-icon v-if="!$v.user.email.$error" key="1" icon="check" class="text-green-700"/>
                  <font-awesome-icon v-if="$v.user.email.$error" key="2" icon="times" class="text-red-700"/>
                </slide-x-left-transition>
              </div>
            </slide-x-left-transition>-->

            <!-- Error message -->
            <!-- <collapse-transition :duration="300" mode="out-in">
              <div v-if="$v.user.email.$error" class="text-red-700 mt-0 text-sm">
                <span v-if="!$v.user.email.email">Неправильный формат e-mail!</span>
                <span v-if="!$v.user.email.required">Требуется заполнить e-mail!</span>
              </div>
            </collapse-transition>-->
          </div>
        </div>

        <SendQ :contentId="video.id" />

        <div v-if="video.tests.length">
          <h2 
            class="text-2xl pb-8 font-medium text-gray-800 mx-auto text-left"
            :class="[{'pt-5': !isUserActive}]"
          >Ассоциированные материалы</h2>
          <div class="flex flex-wrap">
            <TestCard 
              v-for="test in video.tests"
              :key="test.id"
              :title="test.name" 
              :expert="test.expert" 
              :link="{ name: 'Test', params: { test_id: test.id } }"
              :pointsGot="test.poinstDone"
              :pointsTotal="test.pointsTotal"
              :isAvailable="true"
            />
          </div>
        </div>
      </div>
    </fade-transition>

    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      :sitekey="'6Le9nfwUAAAAAF2tc1FbbNoq4ft9BU9vySq-xZxf'"
      @verify="sendLink"
      @expired="onCaptchaExpired"
    />

    <!-- Индикатор загрузки при нажатии "Поделиться" -->
    <loading
      :active="isShareLoading"
      :is-full-page="true"
      :loader="'bars'"
      :color="'#00afd3'"
      :height="90"
      :width="90"
      :opacity="0"
    />

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import TestCard from '@/components/tests/TestCard.vue'
import PublicationCard from '@/components/publications/PublicationCard.vue'
import SendQ from '@/components/SendQ.vue'

import VueRecaptcha from 'vue-recaptcha'

import VideoPlayer from '@/components/videos/VideoPlayer.vue'

export default {
  name: 'ClassVideoPage',
  components: {
    TestCard,
    PublicationCard,
    SendQ,
    FadeTransition,
    LoadingScreen,
    Loading,
    VueRecaptcha,
    VideoPlayer
  },
  data: function() {
    return {
      isLoading: true,
      likesCnt: 14,
      sendLinkEmail: null,
      $script: null,

      // Загрузка "Поделиться"
      isShareLoading: false
    }
  },
  computed: {
    ...mapState({
      video: state => state.videos.currentVideo,
      user: state => state.auth.user     
    }),
    ...mapGetters({
      isUserActive: 'auth/isUserActive'
    }),
    video_id() {
      return Number.parseInt(this.$route.params.video_id)
    }
  },
  created: function() {
    this.getVideo(this.video_id)
      .then((value) => {
        this.isLoading = false
      })
      .catch((error) => {
        if (error.response.status == 403) {
          this.$xmodal.open()
        }
        this.$router.replace({ name: 'Videos' })
      })
  },
  mounted: function() {
    this.$script = document.createElement('script')
    this.$script.async = true
    this.$script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
    document.head.appendChild(this.$script)
  },
  beforeDestroy: function() {
    this.resetVideo()
    document.head.removeChild(this.$script)
  },
  methods: {
    ...mapActions({
      getVideo: 'videos/getVideo', 
      resetVideo: 'videos/resetVideo',
      setContentBreadcrumbs: 'setContentBreadcrumbs',
      addToFavourites: 'videos/addToFavourites', 
      deleteFromFavourites: 'videos/deleteFromFavourites',
      likeOrUnlike: 'videos/likeOrUnlike',
      sendVideoLink: 'videos/sendVideoLink'
    }),

    // SendVideoLink
    verifyCaptcha() {
      this.isShareLoading = true
      this.$refs.recaptcha.execute()
    },
    sendLink(captchaToken) {
      const data = { email: this.sendLinkEmail, video_id: this.video.id, captcha: captchaToken }
      this.sendVideoLink(data)
        .then(() => {
          this.sendLinkEmail = ''
          this.$xmodal.open({
            component: require("@/components/SuccessModal.vue").default,
            backgroundColor: '#aaaaaa',
            opacity: '0.5'
          })
          setTimeout(() => { this.$xmodal.close() }, 1000)
        })
        .finally(() => {
          this.isShareLoading = false
          this.$refs.recaptcha.reset()
        })
    },
    onCaptchaLoad() {
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>