<template>
  <div class="pr-2 md:pr-4 lg:pr-8 vld-parent">

		<!-- Player loading animation -->
		<loading
			:active="isLoading"
			:is-full-page="false"
			:loader="'bars'"
			:color="'#00afd3'"
			:height="90"
			:width="90"
			:opacity="0"
		/>

		<!-- Player -->
		<div
			id="video_div"
			class="relative w-full h-0"
			style="padding-bottom:56.25%"
		></div>

	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import throttle from 'lodash/throttle'

import Player from '@vimeo/player'
import { mapActions, mapState } from 'vuex'

export default {
	name: 'VideoPlayer',
	components: { Loading },
	props: {
		id: {
			type: Number,
			required: true
		},
		url: {
			type: String,
			required: true
		}
	},
	data: function() {
		return {
			isLoading: true,
			player: null,
			isViewSent: false,
			isViewPosted: false,
			stats: {
				video_id: this.id,
				timeSpent: 0,
				position: 0
			},
			preLastPosition: null,
			lastSeconds: 0
		}
	},
	computed: {
		...mapState({
			'timeSpentSent': state => state.videos.timeSpentSent,
			'user': state => state.auth.user
		})
	},
	mounted: function() {
		this.player = new Player('video_div', { url: this.url })
		this.player.setVolume(0.6)
		this.player.ready().then(() => this.isLoading = false)

		const throttleSendStats = throttle(this.sendStats, 5000)

		this.player.on('playing', () => {			
			if (!this.isViewSent && this.user) {
				this.sendView(this.id)
					.then(() => this.isViewPosted = true)
				this.isViewSent = true
			}
		})

		this.player.on('timeupdate', data => {
			if (!this.user && data.seconds > 40) {				
				this.player.pause()
				this.showLogin()
			}

			if ((data.seconds - this.lastSeconds) > 3 == false && (data.seconds - this.lastSeconds) < 0 == false)
				this.stats.timeSpent = parseFloat((this.stats.timeSpent + (data.percent - this.stats.position)).toFixed(3))
			
			this.lastSeconds = data.seconds
			this.stats.position = data.percent
			
			if (this.isViewPosted)
				throttleSendStats(this.stats)
		})
	},
	methods: {
		...mapActions({
			sendView: 'videos/sendView',
			sendViewStats: 'videos/sendViewStats'
		}),
		sendStats(stats) {
			this.sendViewStats(stats)
				.then(() => {
					this.stats.timeSpent = parseFloat((this.stats.timeSpent - this.timeSpentSent).toFixed(3))
				})
		},
		showLogin() {
			this.$xmodal.open({
				component: require("@/components/videos/VideoLoginModal.vue").default,
				backgroundColor: '#aaaaaa',
				opacity: '0.5'
			})
		}
	}
}
</script>

<style>
#video_div iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>